/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat-Regular'),
    url('./fonts/montserrat-v13-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/montserrat-v13-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oxygen-mono-regular - latin */
@font-face {
    font-family: 'Oxygen Mono';
    src: local('Oxygen Mono'), local('OxygenMono-Regular'),
    url('./fonts/oxygen-mono-v6-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/oxygen-mono-v6-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
html, body, #main {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#main {
    background-color: #000000;
    transition: background-color 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
#name {
    color: black;
    transition: color 1s, text-shadow 1s;
    text-shadow: 0 1px  1px black;
    font-size: 5vw;
    font-family: 'Montserrat';
    text-align:center;
}
#time {
    color: black;
    transition: color 1s;
    transition: color 1s, text-shadow 1s;
    text-shadow: 0 2px  1px black;
    text-align: center;
    font-size: 16vw;
    font-family: 'Oxygen Mono';
}

#settings-container {
    display: block;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);

    &.on {
        visibility: visible;
        opacity: 1;
        #settings {
            box-shadow: 0 0 100px rgba(0,0,0,0.8);
            background-color: rgba(255,255,255,0.9);
        }
    }
    #settings {
        font-family: 'Montserrat';
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255,255,255,0);
        transition: left 0.3s, top 0.3s, background-color 0.3s, box-shadow 0.3s;
        box-shadow: 0 0 0 rgba(0,0,0,0);
        display: flex;
        flex-direction: column;

        .settings-form {
            padding: 5pt;
            div {

                margin-top: 10px;
            }
            label {
                font-size: 1rem;
                select {
                    display: block;
                }
                input {
                    display: block;
                }
            }
        }
    }
}

@media (orientation: portrait) {
    #settings-container #settings {
        top: 100%;
        left: 0;
        width: 100%;
        height: 50%;

    }
    #settings-container.on #settings {
        top: 50%;
    }
}
@media (orientation: landscape) {
    #settings-container #settings {
        top: 0;
        left: -50%;
        width: 50%;
        height: 100%;
    }
    #settings-container.on #settings {
        left: 0;
    }
}

